import { toast } from 'react-toastify'
import useSWR from 'swr'
import {
    CHQEarningCode,
    CHQEarningRate,
    ContractorPayroll,
    ContractorPayrollCreateOrUpdate,
    ContractorPayrollEarningUpdate,
    ContractorPayrollMinimal,
    ContractorPayrollReimbursementUpdate,
    EmployeeOrContractorPayrollOfficer,
    EmployeePayroll,
    EmployeePayrollBenefit,
    EmployeePayrollCreateOrUpdate,
    EmployeePayrollEarningUpdate,
    EmployeePayrollMinimal,
    EmployeePayrollPostTaxDeduction,
    EmployeePayrollReimbursementUpdate,
    FirmEarningCode,
    FirmEarningCodeFull,
    FullPayroll,
    Payroll,
    PayrollCreate,
    PayrollPatch,
    PayrollService,
    PayrollTax,
    PayrollWorkplace,
    WirePaymentResponse,
} from '../generated'

import { errorReasonToString } from '../utils/errorUtils'
import { format } from 'date-fns'
import { FunctionStatusType } from './sharedTypes'
import { BelfryAPIEmployeeTaxParameterResponse } from 'src/generated/models/BelfryAPIEmployeeTaxParameterResponse'
import { BelfryEmployeeOrContractorDefaultNetPaySplit } from 'src/generated/models/BelfryEmployeeOrContractorDefaultNetPaySplit'

type LoadEmployeeTaxParametersType = {
    parameters: BelfryAPIEmployeeTaxParameterResponse | undefined
} & FunctionStatusType

type LoadWorkerNetPaySplitType = {
    split: BelfryEmployeeOrContractorDefaultNetPaySplit | undefined
} & FunctionStatusType

export const useLoadFirmPayrollSetupStatus = () => {
    const { data, error } = useSWR(`/firm_payroll_setup_status/`, () =>
        PayrollService.companyStatusApiV1PayrollCompanyStatusGet()
    )
    return data
}

export const useLoadFirmPayrollList = (cursor?: string) => {
    const { data, error } = useSWR([`firm_payroll_list`, cursor], () =>
        PayrollService.getFirmPayrollsPaginatedApiV1PayrollPaginatedListGet(
            cursor
        )
    )
    return data
}

export const useLoadPayrollDetailLink = (id: string | false) => {
    const key = id ? [`/payroll_detail_link/`, id] : null
    const { data, error } = useSWR(
        key,
        () => {
            return PayrollService.getPayrollEditLinkApiV1PayrollEditLinkPost(
                id as any
            )
        },
        { refreshInterval: 0, revalidateOnFocus: false }
    )
    if (error) {
        toast.error(errorReasonToString(error))
    }
    return data
}

type LoadEmployeeTaxParametersFunc = (
    id: string
) => LoadEmployeeTaxParametersType
export const useLoadEmployeeTaxParameters: LoadEmployeeTaxParametersFunc = (
    id
) => {
    const { data, error } = useSWR(`/tax_parameters/${id}`, () =>
        PayrollService.payrollEmployeeTaxParametersApiV1PayrollAdminEmployeeTaxParametersGet(
            id
        )
    )
    return {
        parameters: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadWorkerNetPaySplitFunc = (id: string) => LoadWorkerNetPaySplitType
export const useLoadWorkerNetPaySplit: LoadWorkerNetPaySplitFunc = (id) => {
    const { data, error } = useSWR(`/net_pay_split/${id}`, () =>
        PayrollService.getEmployeeOrContractorNetPaySplitsApiV1PayrollWorkerOfficerIdNetPaySplitsGet(
            id
        )
    )
    return {
        split: data,
        isLoading: !error && !data,
        isError: error,
    }
}

export const useLoadOfficerPayrollSetupStatus = (id: string) => {
    const { data, error } = useSWR([`/officer_payroll_setup_status/`, id], () =>
        PayrollService.officerStatusApiV1PayrollOfficerIdStatusGet(id)
    )
    return data
}

export const useLoadPaySchedule = (
    startDate: Date,
    endDate: Date,
    payGroupId: string | undefined
) => {
    const { data, error } = useSWR(
        [`/payschedule/`, startDate, endDate, payGroupId],
        () =>
            PayrollService.getPayscheduleApiV1PayrollPayscheduleGet(
                format(startDate, 'yyyy-MM-dd'),
                format(endDate, 'yyyy-MM-dd'),
                payGroupId
            )
    )
    return data
}
export const useLoadUpcomingPaySchedule = (payGroupId: string | undefined) => {
    const { data, error } = useSWR([`upcoming_payschedule`, payGroupId], () =>
        PayrollService.getUpcomingPayschedulesApiV1PayrollUpcomingPayschedulesGet(
            payGroupId
        )
    )
    return data
}

export const useLoadCustomEarningCodes = () => {
    const { data, error } = useSWR(`/earning_codes/`, () =>
        PayrollService.getActiveEarningCodeListApiV1PayrollEarningCodeGet()
    )
    return data
}

type createCustomEarningCodeFunc = (
    earningCode: CHQEarningCode,
    mutate: any
) => Promise<any>
export const createCustomEarningCode: createCustomEarningCodeFunc = (
    earningCode,
    mutate
) => {
    return mutate(`/earning_codes/`, async () => {
        return await PayrollService.createEarningCodeApiV1PayrollEarningCodePost(
            earningCode
        ).catch((reason) => toast.error(errorReasonToString(reason)))
    })
}

type downloadPaperChecksPDFFunc = (payrollId: string) => void

export const downloadPaperChecksPDF: downloadPaperChecksPDFFunc = async (
    payrollId
) => {
    await PayrollService.downloadPayrollChecksApiV1PayrollPayrollIdChecksGet(
        payrollId
    )
        .then((res) => {
            const blob = new Blob([res], { type: 'application/pdf' })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `paper-checks-${payrollId}.pdf`
            alink.click()
        })
        .catch((e) => {
            const text = errorReasonToString(e)
            if (text) {
                toast.error(text)
            } else {
                toast.error('Failed to Download PDF')
            }
        })
}

export const downloadCashReqReport: downloadPaperChecksPDFFunc = async (
    payrollId
) => {
    await PayrollService.companyPayrollCashReqReportCsvApiV1PayrollCompanyCashReqReportCsvGet(
        payrollId
    )
        .then((res) => {
            const blob = new Blob([res], { type: 'application/csv' })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `cash-req-${payrollId}.csv`
            alink.click()
        })
        .catch((e) => {
            const text = errorReasonToString(e)
            if (text) {
                toast.error(text)
            } else {
                toast.error('Failed to Download CSV')
            }
        })
}

type downloadW2PreviewFunc = () => void
export const downloadW2Preview: downloadW2PreviewFunc = async () => {
    await PayrollService.payrollCompanyW2PreviewApiV1PayrollCompanyW2PreviewGet()
        .then((res) => {
            const blob = new Blob([res], { type: 'application/pdf' })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `w2-preview.csv`
            alink.click()
        })
        .catch((e) => {
            const text = errorReasonToString(e)
            if (text) {
                toast.error(text)
            } else {
                toast.error('Failed to Download CSV')
            }
        })
}

type PageInfo = {
    page: number
    pageSize: number
    total: number
}
type LoadPayrollsType = {
    payrolls: Payroll[]
    pageInfo: PageInfo | undefined
} & FunctionStatusType

type LoadPayrollsFunc = (pageNum: number, pageSize: number) => LoadPayrollsType
export const useLoadPayrolls: LoadPayrollsFunc = (pageNum, pageSize) => {
    // backend page counts starts at 1, frontend at 0. So we adjust in this func
    const adjustedPageCount = pageNum + 1
    const { data, error } = useSWR([`/payrolls`, pageNum, pageSize], () =>
        PayrollService.listAllPayrollsApiV2PayrollsGet(
            adjustedPageCount,
            pageSize
        )
    )

    return {
        payrolls: data ? data.items : [],
        pageInfo: data && {
            page: (data.page ?? 1) - 1,
            pageSize: data.size ?? 0,
            total: data.total ?? 0,
        },
        isLoading: !error && !data,
        isError: error,
    }
}

type deletePayrollFunc = (payrollId: string, mutate: any) => Promise<Payroll[]>
export const deletePayroll: deletePayrollFunc = (payrollId, mutate) => {
    return mutate(`/payrolls`, async (existing: Payroll[]) => {
        await PayrollService.removePayrollApiV2PayrollsPayrollIdDelete(
            payrollId
        )

        return existing ? existing.filter((p) => p.id != payrollId) : []
    })
}

type createOffCyclePayrollV2Func = (
    payrollCreate: PayrollCreate,
    payrollMode: 'REGULAR' | 'SIDE-BY-SIDE' | 'SHADOW',
    mutate: any
) => Promise<Payroll[]>
export const createBlankOffCyclePayrollV2: createOffCyclePayrollV2Func = (
    payrollCreate,
    payrollMode,
    mutate
) => {
    return mutate(`/payrolls`, async (existing: Payroll[]) => {
        const result =
            await PayrollService.createBlankOffCyclePayrollApiV2PayrollsOffCyclePost(
                payrollMode,
                payrollCreate
            )

        return [result, ...(existing || [])]
    })
}

type SubmitIndividualPayrollFunc = (
    payrollId: string,
    mutate: any
) => Promise<Payroll>
export const submitIndividualPayroll: SubmitIndividualPayrollFunc = (
    payrollId,
    mutate
) => {
    return mutate([`/payrolls`, payrollId], async () => {
        const result =
            await PayrollService.submitPayrollApiV2PayrollsPayrollIdSubmitPost(
                payrollId
            )
        mutate(`/payrolls`, undefined, true)
        mutate([`/payrolls`, payrollId], undefined, true)

        return result
    })
}

type PatchPayrollFunc = (
    payrollId: string,
    patchPayroll: PayrollPatch,
    mutate: any
) => Promise<Payroll>
export const patchPayroll: PatchPayrollFunc = (
    payrollId,
    patchPayroll,
    mutate
) => {
    return mutate([`/payrolls`, payrollId], async () => {
        const result =
            await PayrollService.patchUpdatePayrollApiV2PayrollsPayrollIdPatch(
                payrollId,
                patchPayroll
            )
        mutate(`/payrolls`, undefined, true)
        mutate([`/payrolls`, payrollId], undefined, true)

        return result
    })
}

type ReopenIndividualPayrollFunc = (
    payrollId: string,
    mutate: any
) => Promise<Payroll>
export const reopenIndividualPayroll: ReopenIndividualPayrollFunc = (
    payrollId,
    mutate
) => {
    return mutate([`/payrolls`, payrollId], async () => {
        return await PayrollService.reopenPayrollApiV2PayrollsPayrollIdReopenPost(
            payrollId
        )
    })
}

type LoadIndividualPayrollType = {
    payroll: Payroll | undefined
} & FunctionStatusType

type LoadIndividualPayrollFunc = (
    payrollId: string
) => LoadIndividualPayrollType
export const useLoadIndividualPayroll: LoadIndividualPayrollFunc = (
    payrollId
) => {
    const { data, error } = useSWR([`/payrolls`, payrollId], () => {
        if (payrollId === '') {
            return undefined
        }
        return PayrollService.getPayrollApiV2PayrollsPayrollIdGet(payrollId)
    })

    return {
        payroll: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadEmployeePayrollsType = {
    payrolls: EmployeePayrollMinimal[]
} & FunctionStatusType

type LoadEmployeePayrollsFunc = (payrollId: string) => LoadEmployeePayrollsType
export const useLoadEmployeePayrolls: LoadEmployeePayrollsFunc = (
    payrollId
) => {
    const { data, error } = useSWR([`/payrolls/employees`, payrollId], () =>
        PayrollService.getPayrollEmployeesApiV2PayrollsPayrollIdEmployeesGet(
            payrollId
        )
    )

    return {
        payrolls: data || [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadContractorPayrollsType = {
    payrolls: ContractorPayrollMinimal[]
} & FunctionStatusType

type LoadContractorPayrollsFunc = (
    payrollId: string
) => LoadContractorPayrollsType
export const useLoadContractorPayrolls: LoadContractorPayrollsFunc = (
    payrollId
) => {
    const { data, error } = useSWR([`/payrolls/contractors`, payrollId], () =>
        PayrollService.getPayrollContractorsApiV2PayrollsPayrollIdContractorsGet(
            payrollId
        )
    )

    return {
        payrolls: data || [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadSingleEmployeePayrollType = {
    payroll: EmployeePayroll | undefined
} & FunctionStatusType

type LoadSingleEmployeePayrollFunc = (
    employeePayrollId: string
) => LoadSingleEmployeePayrollType
export const useLoadSingleEmployeePayroll: LoadSingleEmployeePayrollFunc = (
    employeePayrollId
) => {
    const { data, error } = useSWR(
        [`/payrolls/employees/single`, employeePayrollId],
        () =>
            PayrollService.getEmployeePayrollByIdApiV2PayrollsEmployeesEmployeePayrollIdGet(
                employeePayrollId
            )
    )

    return {
        payroll: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadSingleContractorPayrollType = {
    payroll: ContractorPayroll | undefined
} & FunctionStatusType

type LoadSingleContractorPayrollFunc = (
    contractorPayrollId: string
) => LoadSingleContractorPayrollType
export const useLoadSingleContractorPayroll: LoadSingleContractorPayrollFunc = (
    contractorPayrollId
) => {
    const { data, error } = useSWR(
        [`/payrolls/contractors/single`, contractorPayrollId],
        () =>
            PayrollService.getContractorPayrollByIdApiV2PayrollsContractorsContractorPayrollIdGet(
                contractorPayrollId
            )
    )

    return {
        payroll: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadCalculatePayrollV2Type = {
    payroll: FullPayroll | undefined
} & FunctionStatusType
type CalculatePayrollV2Func = (payrollId: string) => LoadCalculatePayrollV2Type
export const useCalculatePayrollV2: CalculatePayrollV2Func = (payrollId) => {
    const { data, error } = useSWR([`/payrolls/full`, payrollId], () =>
        PayrollService.calculatePayrollDetailsApiV2PayrollsPayrollIdFullPost(
            payrollId
        )
    )

    return {
        payroll: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadReadFullPayrollV2Type = {
    payroll: FullPayroll | undefined
} & FunctionStatusType
type ReadFullPayrollV2Func = (payrollId: string) => LoadReadFullPayrollV2Type
export const useReadFullPayrollV2: ReadFullPayrollV2Func = (payrollId) => {
    const { data, error } = useSWR([`/payrolls/full`, payrollId], () =>
        PayrollService.getFullPayrollDetailsApiV2PayrollsPayrollIdFullGet(
            payrollId
        )
    )

    return {
        payroll: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadPayrollV2PostTaxDeductionsType = {
    postTaxDeductions: EmployeePayrollPostTaxDeduction[]
} & FunctionStatusType
type GetPayrollV2PostTaxDeductionsFunc = (
    payrollId: string
) => LoadPayrollV2PostTaxDeductionsType
export const useGetPayrollV2PostTaxDeductions: GetPayrollV2PostTaxDeductionsFunc =
    (payrollId) => {
        const { data, error } = useSWR(
            [`/payrolls/posttaxdeductions`, payrollId],
            () =>
                PayrollService.getPayrollPostTaxDeductionsApiV2PayrollsPayrollIdPosttaxdeductionsGet(
                    payrollId
                )
        )

        return {
            postTaxDeductions: data || [],
            isLoading: !error && !data,
            isError: error,
        }
    }

type LoadPayrollV2BenefitsType = {
    benefits: EmployeePayrollBenefit[]
} & FunctionStatusType
type GetPayrollV2BenefitsFunc = (payrollId: string) => LoadPayrollV2BenefitsType
export const useGetPayrollV2Benefits: GetPayrollV2BenefitsFunc = (
    payrollId
) => {
    const { data, error } = useSWR([`/payrolls/benefits`, payrollId], () =>
        PayrollService.getPayrollBenefitsApiV2PayrollsPayrollIdBenefitsGet(
            payrollId
        )
    )

    return {
        benefits: data || [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadPayrollV2TaxesType = {
    taxes: PayrollTax[]
} & FunctionStatusType
type GetPayrollV2TaxesFunc = (payrollId: string) => LoadPayrollV2TaxesType
export const useGetPayrollV2Taxes: GetPayrollV2TaxesFunc = (payrollId) => {
    const { data, error } = useSWR([`/payrolls/taxes`, payrollId], () =>
        PayrollService.getPayrollTaxesApiV2PayrollsPayrollIdTaxesGet(payrollId)
    )

    return {
        taxes: data || [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadPayrollV2ExcludedContractorsType = {
    contractors: EmployeeOrContractorPayrollOfficer[]
} & FunctionStatusType
type GetPayrollV2ExcludedContractorsFunc = (
    payrollId: string
) => LoadPayrollV2ExcludedContractorsType
export const useGetPayrollV2ExcludedContractors: GetPayrollV2ExcludedContractorsFunc =
    (payrollId) => {
        const { data, error } = useSWR(
            [`/payrolls/contractors/excluded`, payrollId],
            () =>
                PayrollService.getContractorsExcludedFromPayrollApiV2PayrollsPayrollIdContractorsExcludedGet(
                    payrollId
                )
        )

        return {
            contractors: data || [],
            isLoading: !error && !data,
            isError: error,
        }
    }

type LoadPayrollV2ExcludedEmployeesType = {
    employees: EmployeeOrContractorPayrollOfficer[]
} & FunctionStatusType
type GetPayrollV2ExcludedEmployeesFunc = (
    payrollId: string
) => LoadPayrollV2ExcludedEmployeesType
export const useGetPayrollV2ExcludedEmployees: GetPayrollV2ExcludedEmployeesFunc =
    (payrollId) => {
        const { data, error } = useSWR(
            [`/payrolls/employees/excluded`, payrollId],
            () =>
                PayrollService.getEmployeesExcludedFromPayrollApiV2PayrollsPayrollIdEmployeesExcludedGet(
                    payrollId
                )
        )

        return {
            employees: data || [],
            isLoading: !error && !data,
            isError: error,
        }
    }

type GetWirePaymentResponseType = {
    wirePaymentResponse: WirePaymentResponse | undefined
} & FunctionStatusType
type GetWirePaymentResponseFunc = (
    payrollId: string
) => GetWirePaymentResponseType
export const useGetWirePaymentResponse: GetWirePaymentResponseFunc = (
    payrollId
) => {
    const { data, error } = useSWR([`/payrolls/wire_payment`, payrollId], () =>
        PayrollService.getWirePaymentApiV1PayrollPayrollIdWirePaymentGet(
            payrollId
        )
    )

    return {
        wirePaymentResponse: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadPayrollV2EarningRatesType = {
    earningRates: CHQEarningRate[]
} & FunctionStatusType
type GetPayrollV2EarningRatesFunc = (
    officerId: string
) => LoadPayrollV2EarningRatesType
export const useGetPayrollV2EarningRates: GetPayrollV2EarningRatesFunc = (
    officerId
) => {
    const { data, error } = useSWR([`/payrolls/earningrates`, officerId], () =>
        PayrollService.getEmployeeEarningRatesApiV2PayrollEmployeeOfficerIdEarningratesGet(
            officerId
        )
    )

    return {
        earningRates: data || [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadPayrollV2EarningCodesType = {
    earningCodes: FirmEarningCodeFull[]
} & FunctionStatusType
type GetPayrollV2EarningCodesFunc = () => LoadPayrollV2EarningCodesType
export const useGetPayrollV2EarningCodes: GetPayrollV2EarningCodesFunc = () => {
    const { data, error } = useSWR(`/payrolls/earningcodes`, () =>
        PayrollService.getEarningCodesApiV2PayrollEarningcodesGet()
    )

    return {
        earningCodes: data || [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadPayrollV2ContractorEarningCodesType = {
    earningCodes: FirmEarningCode[]
} & FunctionStatusType
type GetPayrollV2ContractorEarningCodesFunc =
    () => LoadPayrollV2ContractorEarningCodesType
export const useGetPayrollV2ContractorEarningCodes: GetPayrollV2ContractorEarningCodesFunc =
    () => {
        const { data, error } = useSWR(`/payrolls/contractorearningcodes`, () =>
            PayrollService.getContractorEarningCodesApiV2PayrollContractorearningcodesGet()
        )

        return {
            earningCodes: data || [],
            isLoading: !error && !data,
            isError: error,
        }
    }

type LoadPayrollV2WorkplacesType = {
    workplaces: PayrollWorkplace[]
} & FunctionStatusType
type GetPayrollV2WorkplacesFunc = () => LoadPayrollV2WorkplacesType
export const useGetPayrollV2Workplaces: GetPayrollV2WorkplacesFunc = () => {
    const { data, error } = useSWR(`/payrolls/workplaces`, () =>
        PayrollService.getWorkplacesApiV2PayrollWorkplacesGet()
    )

    return {
        workplaces: data || [],
        isLoading: !error && !data,
        isError: error,
    }
}

type AddEmployeePayrollEarningFunc = (
    payrollId: string,
    employeePayrollCreateOrUpdate: EmployeePayrollCreateOrUpdate,
    mutate: any
) => Promise<EmployeePayrollMinimal[]>
export const addOrUpdateEmployeePayroll: AddEmployeePayrollEarningFunc = (
    payrollId,
    employeePayrollCreateOrUpdate,
    mutate
) => {
    return mutate([`/payrolls`, payrollId], async () => {
        const result =
            await PayrollService.createOrUpdatePayrollEmployeesApiV2PayrollsPayrollIdEmployeesPost(
                payrollId,
                employeePayrollCreateOrUpdate
            )

        mutate([`/payrolls`, payrollId], undefined, true)

        return result
    })
}

type AddContractorPayrollEarningFunc = (
    payrollId: string,
    contractorPayrollCreateOrUpdate: ContractorPayrollCreateOrUpdate,
    mutate: any
) => Promise<ContractorPayrollMinimal[]>
export const addOrUpdateContractorPayroll: AddContractorPayrollEarningFunc = (
    payrollId,
    contractorPayrollCreateOrUpdate,
    mutate
) => {
    return mutate([`/payrolls`, payrollId], async () => {
        const result =
            await PayrollService.createOrUpdatePayrollContractorsApiV2PayrollsPayrollIdContractorsPost(
                payrollId,
                contractorPayrollCreateOrUpdate
            )

        mutate([`/payrolls`, payrollId], undefined, true)

        return result
    })
}

type UpdateEmployeePayrollEarningFunc = (
    id: string,
    payrollId: string,
    officerId: string,
    employeePayrollEarningUpdate: EmployeePayrollEarningUpdate,
    mutate: any
) => Promise<EmployeePayrollMinimal>
export const updateEmployeePayrollEarning: UpdateEmployeePayrollEarningFunc = (
    id,
    payrollId,
    officerId,
    employeePayrollEarningUpdate,
    mutate
) => {
    return mutate([`/payrolls/employees/single`, id], async () => {
        const result =
            await PayrollService.updatePayrollEmployeeEarningApiV2PayrollsPayrollIdEmployeesOfficerIdEarningsIdPost(
                id,
                payrollId,
                officerId,
                employeePayrollEarningUpdate
            )

        mutate([`/payrolls`, payrollId], undefined, true)

        return result
    })
}

type UpdateEmployeePayrollReimbursementFunc = (
    id: string,
    payrollId: string,
    officerId: string,
    updateEmployeePayrollReimbursement: EmployeePayrollReimbursementUpdate,
    mutate: any
) => Promise<EmployeePayrollMinimal>
export const updateEmployeePayrollReimbursement: UpdateEmployeePayrollReimbursementFunc =
    (id, payrollId, officerId, updateEmployeePayrollReimbursement, mutate) => {
        return mutate([`/payrolls/employees/single`, id], async () => {
            const result =
                await PayrollService.updatePayrollEmployeeReimbursementApiV2PayrollsPayrollIdEmployeesOfficerIdReimbursementsIdPost(
                    id,
                    payrollId,
                    officerId,
                    updateEmployeePayrollReimbursement
                )

            mutate([`/payrolls`, payrollId], undefined, true)

            return result
        })
    }

type UpdateContractorPayrollEarningFunc = (
    id: string,
    payrollId: string,
    officerId: string,
    contractorPayrollEarningUpdate: ContractorPayrollEarningUpdate,
    mutate: any
) => Promise<ContractorPayrollMinimal>
export const updateContractorPayrollEarning: UpdateContractorPayrollEarningFunc =
    (id, payrollId, officerId, contractorPayrollEarningUpdate, mutate) => {
        return mutate([`/payrolls/contractors/single`, id], async () => {
            const result =
                await PayrollService.updatePayrollContractorEarningApiV2PayrollsPayrollIdContractorsOfficerIdEarningsIdPost(
                    id,
                    payrollId,
                    officerId,
                    contractorPayrollEarningUpdate
                )

            mutate([`/payrolls`, payrollId], undefined, true)

            return result
        })
    }

type UpdateContractorPayrollReimbursementFunc = (
    id: string,
    payrollId: string,
    officerId: string,
    contractorPayrollReimbursementUpdate: ContractorPayrollReimbursementUpdate,
    mutate: any
) => Promise<ContractorPayrollMinimal>
export const updateContractorPayrollReimbursement: UpdateContractorPayrollReimbursementFunc =
    (
        id,
        payrollId,
        officerId,
        contractorPayrollReimbursementUpdate,
        mutate
    ) => {
        return mutate([`/payrolls/contractors/single`, id], async () => {
            const result =
                await PayrollService.updatePayrollContractorReimbursementApiV2PayrollsPayrollIdContractorsOfficerIdReimbursementsIdPost(
                    id,
                    payrollId,
                    officerId,
                    contractorPayrollReimbursementUpdate
                )

            mutate([`/payrolls`, payrollId], undefined, true)

            return result
        })
    }
